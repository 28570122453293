import React from "react";
import styles from "./GameOver.module.css";
import { useNavigate } from "react-router-dom";


const Login = () => {
  const navigate = useNavigate();
  // const [message, setMessage] = useState();

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <h1>Congratulations</h1>
        <div  className={styles.message}>You finished the game!</div>
        <div className={styles.message}>But wait, That's not the end!</div>
        <div className={styles.message}>
                      The decision is your own voice, an opinion is the echo of
                      someone else's voice: Choose the right one.
                      </div>
        <h1>Reverberate.<br/> Coming Soon.</h1>
        <button className={styles.btn} onClick={() => navigate("/leaderboard")}>Go to Leaderboard</button>
      </div>
    </div>
  );
};

export default Login;
